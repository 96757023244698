.titleHeader {
    background: linear-gradient(to left, transparent, rgb(224, 218, 240), rgb(225, 223, 233), rgb(215, 210, 228), transparent);
    background-size: 200% auto;
    font-weight: 900;
    font-size: 1.5rem;
    font-family: 'Asap-Regular';
    border-bottom: 3px solid rgb(208, 202, 226);
    margin-bottom: 2rem;
    padding: 1rem;
    animation: shimmer 2s infinite linear;
}

@keyframes shimmer {
    to {
        background-position: 200% center;
    }
}

a {
    text-decoration: none;
    color: black;
}

a:hover {
    color: lightblue;
}

.linkedInIcon {
    fill: rgb(58, 58, 182);
    cursor: pointer;
    font-size: 2rem;
}

.gitHubIcon {
    cursor: pointer;
    font-size: 2rem;
}

.linkedInIcon:hover {
    fill: lightblue;
}

.gitHubIcon:hover {
    fill: lightblue;
}

.foundingFathersDiv {
    margin-top: 1rem;
}

.linksFooter {
    display: flex;
    justify-content: space-between;
    padding: 1rem 2rem 0rem 0rem;
    margin-top: 1rem;
}

.homeDiv {
    display: flex;
    align-items: center;
}

.homeDiv>* {
    margin-right: 1rem;
}