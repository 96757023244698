.sideBarItem {
  margin-left: 3vw;
  margin-right: 0;
  padding-right: 3rem;
  font-weight: 700;
  font-size: 1.7rem;
  letter-spacing: 3px;
  cursor: pointer;
  transition: 0.4s;
  animation: enterGlow var(--delay) ease-out;
  color: white;
}

.sideBarItem:hover {
  color: darkblue;
}

@keyframes enterGlow {
  from {
    color: darkblue;
    text-shadow: 1px 1px midnightblue;
  }
}
