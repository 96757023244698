.headShotCurtain {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 40vh;
  width: 40vw;

  background: #708090;
  background: linear-gradient(
    to Right,
    white,
    white,
    white,
    white,
    #708090,
    hsl(244, 9%, 32%)
  );
  transition: 1s cubic-bezier(0.4, 0, 0.4, 1);
}

.headShot {
  width: 100%;
  padding-right: 15%;
  transition: 1s cubic-bezier(0.4, 0, 0.4, 1);
}

.storyCurtain {
  position: absolute;
  top: 40vh;
  right: 0;
  width: 65vw;
  color: rgb(237, 240, 244);
  transition: 1s cubic-bezier(0.4, 0, 0.4, 1);
  letter-spacing: 0.11em;
  line-height: 1.3em;

}

.storyBlock {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 25%;
  font-size: 14px;
  font-weight: 700;
  color: rgb(2, 13, 29);
  line-height: 1.8em;
  padding: 0;
  margin: 0;
}

.topThirdStory {
  padding-top: 3rem;
  transition: 0.5s cubic-bezier(0.4, 0, 0.4, 1);
}

.middleThirdStory {
  transition: 0.9s cubic-bezier(0.4, 0, 0.4, 1);
}

.bottomThirdStory {
  transition: 1.3s cubic-bezier(0.4, 0, 0.4, 1);
  margin-bottom: 7rem;
}

.bottomThirdStory h2 {
  margin-bottom: 10rem;
}
