* {
  font-weight: 900;
}

body {
  margin: 0;
  height: 100vh;
  background: linear-gradient(to top, #949cc2, #2b2d3f);
  background-repeat: no-repeat;
  /* font-family: 'Sohne', sans-serif; */
  /* font-family: 'Futura', sans-serif; */
  font-family: 'Asap-Regular';

  letter-spacing: 0.3em;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* overflow: hidden; */
}

@media (min-width: 768px) {
  body {
    overflow: hidden;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}



@font-face {
  font-family: 'Quilon-Medium';
  src: url('./fonts/Quilon-Medium.woff2') format('woff2'), url('./fonts/Quilon-Medium.woff') format('woff'),
    url('./fonts/Quilon-Medium.ttf') format('truetype');
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'Asap-Regular';
  src: url('./fonts/Asap-Regular.woff2') format('woff2'),
    url('./fonts/Asap-Regular.woff') format('woff'),
    url('./fonts/Asap-Regular.ttf') format('truetype');
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}


/**
 * @license
 *
 * Font Family: Quilon
 * Designed by: Jonny Pinhorn
 * URL: https://www.fontshare.com/fonts/quilon
 * © 2023 Indian Type Foundry
 *
 * Font Styles:
 * Quilon Variable(Variable font)
 * Quilon Regular
 * Quilon Medium
 * Quilon Semibold
 * Quilon Bold
 *
*/

/**
* This is a variable font
* You can controll variable axes as shown below:
* font-variation-settings: 'wght' 400.0;
*
* available axes:

* 'wght' (range from 400.0 to 700.0)

*/
/* 
@font-face {
  font-family: 'Quilon-Variable';
  src: url('../fonts/Quilon-Variable.woff2') format('woff2'), url('../fonts/Quilon-Variable.woff') format('woff'),
    url('../fonts/Quilon-Variable.ttf') format('truetype');
  font-weight: 400 700;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'Quilon-Regular';
  src: url('./fonts/Fonts/WEB/fonts/Quilon-Regular.ttf') format('woff2'), url('./fonts/Fonts/WEB/fonts/Quilon-Regular.ttf') format('woff'),
    url('./fonts/Fonts/WEB/fonts/Quilon-Regular.ttf') format('truetype');
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'Quilon-Medium';
  src: url('./fonts/Fonts/WEB/fonts/Quilon-Medium.woff2') format('woff2'), url('./fonts/Fonts/WEB/fonts/Quilon-Medium.woff') format('woff'),
    url('./fonts/Fonts/WEB/fonts/Quilon-Medium.ttf') format('truetype');
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'Quilon-Semibold';
  src: url('./fonts/Fonts/WEB/fonts/Quilon-Semibold.woff2') format('woff2'), url('./fonts/Fonts/WEB/fonts/Quilon-Semibold.woff') format('woff'),
    url('./fonts/Fonts/WEB/fonts/Quilon-Semibold.ttf') format('truetype');
  font-weight: 600;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'Quilon-Bold';
  src: url('./fonts/Fonts/WEB/fonts/Quilon-Bold.woff2') format('woff2'), url('./fonts/Fonts/WEB/fonts/Quilon-Bold.woff') format('woff'),
    url('./fonts/Fonts/WEB/fonts/Quilon-Bold.ttf') format('truetype');
  font-weight: 700;
  font-display: swap;
  font-style: normal;
} */