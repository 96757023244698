.sideBarDiv {
  position: absolute;
  margin-top: 50vh;
  border-top-right-radius: 6px;
  top: 0;
  display: flex;
  flex-direction: column;
  height: 50vh;
  width: 50vw;
  text-align: end;
  background: linear-gradient(to Right, transparent, rgb(65, 62, 76), rgb(48, 48, 67));
  /* background-size: 200% auto; */
  transform: translateX(2vw);
  animation: flare 1s cubic-bezier(0.19, 1, 0.45, 0.99);
  transition: 1s;
}

/* @keyframes shimmer {
  to {
    background-position: 200% center;
  }
} */

@keyframes flare {
  0% {
    background: linear-gradient(to Right, transparent, rgb(102, 97, 122), rgb(102, 102, 171));
    transform: translateX(-35vw);
  }

  100% {
    background: linear-gradient(to Right, transparent, rgb(65, 62, 76), rgb(48, 48, 67));
    transform: translateX(2vw);
  }
}
