.contactBox {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* padding: 1rem; */
  width: 100vw;
  height: 80vh;

  background: white;
  background: linear-gradient(
    #476097,
    transparent,
    white,
    white,
    white,
    white,
    transparent
  );
  transition: 0.75s cubic-bezier(0.4, 0, 0.4, 1);
  overflow: hidden;

}

.contactBoxHeader {
  margin: 1rem 1rem;
}

.h2 {
  font-weight: 800;
  font-size: 1rem;
}

.inputsBox {
  display: flex;
  flex-direction: column;
}

.input {
  background-color: rgb(166, 170, 175);
  border: 0.1rem solid darkslateblue;
  font-family: "Quicksand";
  width: 60vw;
  font-size: 0.75rem;
  padding: 1rem;
  transition: 1s;
}

textarea {
  background-color: rgb(166, 170, 175);
  border: 0.1rem solid darkslateblue;

}

.submitButton {
  width: fit-content;
  background: none;
  color: darkslateblue;
  border: 4px solid darkslateblue;
  padding: 4px 25px;
  margin: 1rem;
  border-radius: 100px;
  font-size: 1rem;
  font-weight: 900;
  text-transform: uppercase;
  cursor: pointer;
  float: right;
  transition: all 0.25s ease-out;
}

.submitButton:hover {
  background: darkslateblue;
  color: white;
}

.myContactInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: 1s cubic-bezier(0.4, 0, 0.4, 1);
}

.myContactInfo h2 {
  font-weight: 900;
  font-size: 1rem;
}

.messageInput {
  margin-top: 2rem;
  padding: 1rem;
  width: 60vw;
  height: 20%;
}

.myEmail {
  margin: auto auto;
  font-size: 1rem;
}
