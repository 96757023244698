.outerContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  /* margin-top: 14vh; */
  width: 100vw;
  height: 270vh;
  /* background: white; */
  background: linear-gradient(#1c2436, transparent, transparent, transparent);
  /* transition: 0.75s cubic-bezier(0.4, 0, 0.4, 1); */
  z-index: 5;
  animation: floatUp cubic-bezier(0.25, 0.1, 0.25, 1) 1s;
}

@keyframes floatUp {
  from {
    transform: translateY(100vh);
  }
  to {
    transform: translateY(0);
  }
}

.formDiv {
  margin: 3rem 1rem 1rem 2rem;
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.control {
  font: inherit;
  padding: .5rem;
  border-radius: 4px;
  background-color: #afaaaa;
  border: 1px solid #c1d1d1;
  display: block;
  width: fit-content;
  font-size: 1.2rem;
}

.control:focus,
.control:focus {
  background-color: #e9dcdc;
  outline-color: rgb(46, 40, 39);
}

.button {
  width: fit-content;
  background: none;
  color: #eccbcb;
  border: 4px solid #eccbcb;
  padding: 5px 20px;
  margin-top: 4rem;
  border-radius: 100px;
  font-size: 2rem;
  font-weight: 700;
  text-transform: uppercase;
  cursor: pointer;
  float: right;
  transition: all 0.25s ease-out;
}

.button:hover {
  background: #eccbcb;
  color: rgb(24, 15, 92);
}
