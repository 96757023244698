.outerContainer {
  display: flex;
  height: 90vh;
  justify-content: space-around;
  /* Distribute space evenly between cards */
  align-items: center;
  /* Vertically center the cards */
}

.cardContainer {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90%;
}

.card {
  width: 60%;
  height: 50%;
  cursor: pointer;
}


.cardImage {
  width: 80%;
  height: auto;
  border-radius: 20px;
  transition: filter 0.3s ease;
  box-shadow: 0 3px 5px rgba(140, 136, 218, 0.2);

}

.cardImage:hover {
  filter: brightness(1.5);
}