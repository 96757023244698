/* Header.module.css */


.headerContainer {
  /* font-family: 'Quilon-Medium'; */

  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100vw;
  overflow: hidden;
  position: fixed;
  top: 3rem;
}

h1 {
  padding: 0;
  margin: 0;
}

.nameAndSubTitle {
  margin: 1rem 0rem 0rem 1rem
}

.visible {
  opacity: 1;
  transform: translateX(0);
}

.subtitle {
  margin: 0;
}

.hidden {
  opacity: 0;
  transform: translateX(100%);
  transition: opacity 2s ease, transform 2s ease;
  /* Adjust the transition duration */
}

.header {
  color: white;
  font-size: 5rem;
  font-family: 'Futura Light Italic';
  display: inline-block;
  position: relative;
  /* transform: perspective(1000px) translateZ(-50px);  */
}

.flyInLetter {
  font-family: 'Asap-Regular';
  opacity: 0;
  transform: translateX(100%);
  animation: flyIn 1s ease forwards;
}

@keyframes flyIn {
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.caps {
  font-size: 4rem;
}

.lower {
  font-size: 2rem;
}

/* Adjust font weight and style for the subtitle */
.subtitle {
  /* transform: translateX(25%) scale(1.2); */
  color: transparent;
  font-size: 2rem;
  /* Adjust the font size */
  font-weight: lighter;
  /* Adjust font weight (e.g., normal, lighter, bold) */
  text-shadow: 0px 0px 2px rgba(255, 255, 255, 0.5);
  /* Smaller blur radius */
  animation: fadeIn 3s ease forwards;
  /* Slower animation */
  animation-delay: 1s;
  /* Delay the fog effect */
  opacity: 0;
  /* Start with opacity at 0 */
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    /* Start with opacity at 0 */
  }

  100% {
    opacity: 1;
    /* Gradually reach full opacity */
  }
}