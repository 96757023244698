.contactBox {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 5vh;
  width: 100vw;
  height: 90vh;
  background: white;
  background: linear-gradient(#1c2436, transparent, transparent, transparent);
  transition: 0.75s cubic-bezier(0.4, 0, 0.4, 1);
  z-index: 5;
}

.contactBoxHeader {
  margin: 1rem 0;
}

.h2 {
  font-weight: 800;
  font-size: 2rem;
  margin: 1rem 0;
  color: white;
}

.inputsBox {
  display: flex;
  flex-direction: row;
}

.input {
  background-color: rgb(166, 170, 175);
  border: 0.1rem solid #1c2436;
  border-radius: 3px;
  font-family: 'Quicksand';
  width: 20vw;
  font-size: 1.5rem;
  padding: 1rem;
  transition: 1s;
}

textarea {
  background-color: rgb(166, 170, 175);
  border-radius: 3px;
}

.submitButton {
  width: fit-content;
  background: none;
  color: #1c2436;
  border: 4px solid #1c2436;
  padding: 4px 25px;
  margin: 1rem;
  border-radius: 100px;
  font-size: 2rem;
  font-weight: 900;
  text-transform: uppercase;
  cursor: pointer;
  float: right;
  transition: all 0.25s ease-out;
}

.submitButton:hover {
  background: #1c2436;
  color: white;
}

.myContactInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: 1s cubic-bezier(0.4, 0, 0.4, 1);
}

.myContactInfo h2 {
  font-weight: 900;
  font-size: 2rem;
  color: #1c2436;
}

.messageInput {
  margin-top: 2rem;
  width: 25%;
  height: 20%;
}

.myEmail {
  margin: auto auto;
  color: #1c2436;
  font-weight: 900;
}
