.headShotCurtain {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 16vh;
  width: 35vw;
  height: 84vh;
  border-radius: 10px;
  background: #708090;
  background: transparent;
  transition: 1s cubic-bezier(0.4, 0, 0.4, 1);
}

.headShot {
  width: 20vw;
  border-radius: 5%;
  /* mask-image: linear-gradient(to Right, transparent, white 10%, white 90%, transparent); */
  transition: 1s cubic-bezier(0.4, 0, 0.4, 1);
}

.storyCurtain {
  font-family: 'Asap-Regular';
  position: absolute;
  margin: 4rem 10rem 0 0;
  top: 40vh;
  right: 0;
  width: 50vw;
  height: 45vh;
  transition: 1s cubic-bezier(0.4, 0, 0.4, 1);
  color: white;
  font-size: 1.5rem;
  line-height: 2.5rem;
  letter-spacing: 0.1em;
  z-index: 5;
}

.storyBlock {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 10%;
  font-size: 1.5rem;
  font-weight: 700;
  color: rgb(224, 232, 244);
  line-height: 1.8em;
  letter-spacing: 0.15em;
  padding: 0;
  margin: 0;
}


